import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

const EsContent = () => {
  return (
    <div>
      <h1 className='page-title'>Política de privacidad consumidores</h1>
      <div className='section-wrapper'>
        <p>
          La confidencialidad y la seguridad son valores primordiales para AGVAR BYTHEY, S.L. y, en consecuencia,
          asumimos el compromiso de garantizar la privacidad del usuario en todo momento y de no recabar información
          innecesaria. A continuación, le proporcionamos toda la información necesaria sobre nuestra Política de
          Privacidad en relación con los datos personales que recabamos, explicándole
        </p>
        <ul>
          <li>Quién es el responsable del tratamiento de sus datos.</li>
          <li>Para qué finalidades recabamos los datos que le solicitamos.</li>
          <li>Cuál es la legitimación para su tratamiento.</li>
          <li>Durante cuánto tiempo los conservamos.</li>
          <li>A qué destinatarios se comunican sus datos.</li>
          <li>Cuáles son sus derechos.</li>
        </ul>
      </div>
      <div className='section-wrapper'>
        <p className='section-title'>1. RESPONSABLE DEL TRATAMIENTO</p>
        <p>
          AGVAR BYTHEY, S.L.
          <br />
          NIF B67498394
          <br />
          C/Major de Sarriá nº 172
          <br />
          08017 Barcelona
          <br />
          España
          <br />
          manu@bethey.com
        </p>
        <p>
          En adelante “BeThey”, “nosotros” o el “Responsable del Tratamiento”. Esta Política de Privacidad cubre todos
          los datos recopilados y utilizados por nosotros en la aplicación móvil BETHEY (en adelante, “la Plataforma” o
          “BeThey”, indistintamente).
        </p>
      </div>
      <div className='section-wrapper'>
        <p className='section-title'>2. DATOS PERSONALES RECOPILADOS</p>
        <p>
          Datos Personales hace referencia a cualquier información o datos que pueda identificarlo, ya sea directa o
          indirectamente. En este sentido, los datos personales recopilados por BeThey incluyen:
        </p>
        <p className='section-subtitle'>Datos de los usuarios:</p>
        <p>
          Información que usted nos proporciona directamente al registrarse y al utilizar nuestros Servicios. Cuando
          usted crea una cuenta, recopilamos: Su nombre completo, alias, dirección de correo electrónico y fecha de
          nacimiento. Si Usted lo desea, puede facilitarnos una fotografía de su imagen que se utilizará para
          identificar a su perfil. Del mismo modo, recopilaremos sus datos de su tarjeta bancaria para gestionar el pago
          de los vídeos que compre.
        </p>
        <p>
          A este respecto, para su seguridad, hemos confiado en el sistema de pago mediante tarjeta de crédito o débito
          a una pasarela de pago segura. Los datos bancarios introducidos son encriptados y transmitidos de forma segura
          a los servidores de la entidad bancaria y posteriormente, son verificados con el banco emisor para evitar
          posibles fraudes y abusos.
        </p>
        <p>
          Asimismo, podemos recopilar otros datos tales como sus gustos y/o preferencias, a quienes sigue, contenido
          compartido y/o mensajes y comunicaciones intercambiadas con otros usuarios, así como cualquier otro dato que
          nos pueda proporcionar voluntariamente.
        </p>
        <p>
          <strong>Obligación de facilitarnos sus datos personales y consecuencias de no hacerlo.</strong>
        </p>
        <p>
          Los datos personales solicitados son necesarios para gestionar sus solicitudes y/o prestarle los servicios
          propios de BeThey, por lo que, si no nos los facilita, no podremos atenderle correctamente ni préstale los
          servicios que ha solicitado.
        </p>
        <p>
          En todo caso, nos reservamos el derecho de decidir sobre la incorporación o no de sus datos personales y demás
          información a nuestras bases de datos
        </p>
        <p>
          - Del mismo modo, también recopilamos información que usted nos proporciona indirectamente al crear una cuenta
          y al utilizar nuestros Servicios:
        </p>
        <ul>
          <li>
            Información de cómo usa nuestros productos, las funciones que utiliza, las acciones que lleva a cabo, las
            personas o cuentas con las que interactúa y cómo interactúa con ellos.
          </li>
          <li>
            Información derivada del uso que da a la Plataforma de forma agregada para implementar mejoras sobre el
            funcionamiento de la Plataforma y proporcionarle recomendaciones que podrían ser de su interés.
          </li>
        </ul>
        <p>
          La Plataforma BeThey, también permite el registro mediante la sincronización con servicios de terceros (Log-in
          con cuenta de Facebook). Si sigue un enlace a este sitio web, tenga en cuenta que comparte su información de
          perfil social con nosotros. Los datos personales compartidos dependen de la configuración de su Plataforma de
          medios sociales. Visite la Plataforma de redes sociales en cuestión y revise su política de privacidad para
          comprender cómo se comparten y usan sus datos personales en este contexto. Se heredarán los valores de todos
          los campos disponibles en su perfil. El suministro de Datos Personales requiere una edad mínima de 18 años o,
          en su caso, disponer de capacidad jurídica suficiente para contratar.
        </p>
      </div>
      <div className='section-wrapper'>
        <p className='section-title'>3. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES</p>
        <p>Los datos personales facilitados a través del uso de la Plataforma BeThey son necesarios para:</p>
        <p>
          - Identificarlo como usuario y poder darle acceso a las diferentes funcionalidades, productos y servicios que
          están a su disposición como usuario registrado.
        </p>

        <p>- Seguir a creadores de contenido y estar al tanto de sus vídeos y novedades.</p>

        <p>- Correcta ejecución de la compra de bonos para la visualización de vídeos de los Creadores.</p>

        <p>- Realizar una correcta gestión de los servicios y brindarle el servicio solicitado.</p>

        <p>- Gestionar y hacer seguimiento de la relación contractual y precontractual que se mantiene con usted.</p>

        <p>
          - Posibilitar el contacto con otros usuarios de la plataforma y posibilitar la denuncia de contenidos
          inapropiados o spam.
        </p>

        <p>- Responder a sus preguntas y/o peticiones. </p>

        <p>
          - Comunicarnos con usted, incluso por correo electrónico y/o mensajes de texto (solo si usted lo acepta), en
          relación con nuevos servicios, actualizaciones o cualquier otro asunto que consideremos que puede ser de
          interés para usted.
        </p>

        <p>- Mejorar, desarrollar y probar nuestra Plataforma.</p>

        <p>- Proteger los derechos, la propiedad y/o la seguridad de BeThey.</p>

        <p>
          - Envío de alertas (notificaciones push) que le informan de nuevas publicaciones de usuarios creadores que
          usted sigue, siempre y cuando haya aceptado expresamente para ello. En cualquier momento, podrá revocar su
          consentimiento desde su perfil.
        </p>

        <p>
          - Por otro lado, para el correcto funcionamiento de la Plataforma, utilizamos cookies técnicas y funcionales
          para:
        </p>
        <ul>
          <li>
            La correcta recepción de recordatorios, recomendaciones técnicas, actualizaciones del servicio de la
            Plataforma, alertas de seguridad y mensajes de soporte.{' '}
          </li>

          <li>
            Mejorar los servicios a los que podría suscribirse mediante el estudio de su comportamiento como usuario,
            para adaptarlo a sus necesidades y gustos.{' '}
          </li>

          <li>La realización de informes y estadísticas, de manera disociada. </li>

          <li>
            Investigar, desarrollar y mejorar los servicios a los usuarios o para ofrecer nuevos servicios,
            características y herramientas.
          </li>
        </ul>
        <p>
          Si quiere obtener información detallada sobre las cookies que utilizamos, puede consultar nuestra política de
          cookies{' '}
          <Link to={routes.cookies} target='_blank'>
            aquí
          </Link>
        </p>
      </div>
      <div className='section-wrapper'>
        <p className='section-title'>4. LEGITIMACIÓN PARA EL TRATAMIENTO DE LOS DATOS PERSONALES</p>
        <p>
          - Gestionar su registro como usuario de la Plataforma: El tratamiento de sus datos es necesario para la
          ejecución de los términos que regulan el uso de la Plataforma. En el caso de que elija utilizar el acceso o
          inicio de sesión a través una red social, el motivo por el que estamos legitimados para tratar sus datos es el
          consentimiento que presta en el momento de autorizar la cesión de sus datos desde la red social. Del mismo
          modo, consideramos que tenemos un interés legítimo para realizar las comprobaciones necesarias para detectar y
          prevenir posibles fraudes cuando se registra en la Plataforma.
        </p>
        <p>
          - Atención al usuario: Desde BeThey tenemos un interés legítimo para atender las solicitudes o consultas que
          nos plantee a través de los diversos medios de contacto existentes. Entendemos que el tratamiento de estos
          datos resulta también beneficioso para usted en tanto que nos permite poder atenderle adecuadamente y resolver
          las consultas planteadas. Cuando nos contacte para la gestión de incidencias relacionadas con el uso de la
          Plataforma, el tratamiento es necesario para la ejecución de la relación contractual mantenida con usted.
          Cuando su consulta esté relacionada con el ejercicio de los derechos sobre los que le informamos más abajo, o
          con reclamaciones relacionadas con nuestros servicios, lo que nos legitima para tratar sus datos es el
          cumplimiento de obligaciones legales por nuestra parte.
        </p>
        <p>
          - Envío de comunicaciones push: La base legítima es el consentimiento que nos presta (por ejemplo: cuando
          autoriza el envío de notificaciones push en su dispositivo móvil, o cuando acepta las bases legales para
          publicar sus fotografías o vídeos en la Plataforma). Para mostrarle información personalizada, consideramos
          que tenemos un interés legítimo para realizar un perfilado con la información que tenemos sobre usted y los
          datos personales que nos ha facilitado, ya que entendemos que el tratamiento de estos datos también resulta
          beneficioso para usted porque le permite mejorar su experiencia como usuario y acceder a información conforme
          a sus preferencias.
        </p>
        <p>
          - Calidad: Consideramos que tenemos un interés legítimo para analizar la usabilidad de la Plataforma y el
          grado de satisfacción del usuario, ya que la finalidad es mejorar la experiencia del usuario y ofrecer un
          servicio de mayor calidad.
        </p>
      </div>
      <div className='section-wrapper'>
        <p className='section-title'>5. DESTINATARIOS DE LOS DATOS PERSONALES RECABADOS</p>
        <p>Sus Datos Personales podrán ser comunicados a terceros, esto es:</p>
        <p>
          - Personas y usuarios con las que se comunica y comparte información. Cuando se comunica y comparte
          información usando nuestra Plataforma, Usted elige los usuarios que pueden ver lo que comparte. Entre estos
          datos se incluye su nombre de usuario, así como la información que comparte de forma pública.
        </p>

        <p>
          - Cuando comenta una publicación de un Creador o reacciona a su contenido, cualquiera podrá ver su comentario
          o reacción, así como tener acceso a los datos de su perfil y cualquier otra publicación pública que haya
          realizado con anterioridad.
        </p>

        <p>
          - Cuando decide utilizar aplicaciones, sitios web y otros servicios de terceros que usan BeThey o están
          integrados con ellos, estas plataformas pueden recibir información acerca de sus publicaciones o del contenido
          que comparte.
        </p>

        <p>
          - Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de La Plataforma, servicios de
          alojamiento, mantenimiento y soporte en nuestras bases de datos, así como en nuestro software y aplicaciones
          que pueden contener datos sobre usted.{' '}
        </p>

        <p>
          - Terceros que nos ayudan a proporcionar servicios digitales y reseñas, CRM, análisis web y motor de búsqueda.{' '}
        </p>

        <p>
          - Agencias de publicidad, marketing, medios digitales y redes sociales para ayudarnos a ofrecer publicidad,
          marketing y campañas, para analizar su efectividad y para administrar su contacto y sus preguntas.{' '}
        </p>

        <p>
          - Por eficiencia del servicio, en ocasiones sus datos podrán ser comunicados a prestadores de servicios que
          puedan estar ubicados en Estados Unidos de América u otros países o territorios situados fuera del Espacio
          Económico Europeo. No obstante, nos aseguramos de que toda comunicación de su información personal que
          realicemos nosotros o aquellos terceros con los que compartimos su información personal, cumplan con la
          legislación que nos sea de aplicación.{' '}
        </p>
      </div>

      <div className='section-wrapper'>
        <p className='section-title'>6. CONSERVACIÓN DE SUS DATOS PERSONALES</p>
        <p>
          Conservaremos únicamente sus Datos Personales durante el periodo de tiempo que necesitemos a propósito de la
          finalidad para los que los tratamos, hasta que se elimine su cuenta o durante el periodo de tiempo legalmente
          establecido para ello.
        </p>
        <p>
          Al eliminar su cuenta, eliminamos el contenido que ha publicado, así como las fotografías, por lo que no podrá
          recuperar esta información. En cualquier caso, sus datos podrían conservarse por imperativo legal, por el
          periodo de tiempo establecido, sin embargo, tales datos quedarán bloqueados. Por el contrario, la información
          que hayan compartido de usted otras personas no se eliminará, ya que no forma parte de su cuenta.
        </p>
        <p>
          Los Datos Personales obtenidos a través del registro a la Plataforma, los obtenidos a través de una consulta a
          través de los formularios de consultas o a través de la dirección de correo electrónico se conservarán hasta
          que se realice el servicio o durante el tiempo de atención para la resolución de su consulta. En el caso de
          recibir su CV, sus datos podrán ser conservados durante un año máximo para futuros procesos de selección.
        </p>
        <p>
          Los Datos Personales obtenidos a través de su consentimiento expreso para el envío de comunicaciones push, se
          mantendrán hasta que usted revoque el consentimiento y7 o solicite la baja del servicio.
        </p>
        <p>
          Es posible que retengamos algunos datos personales para cumplir con nuestras obligaciones legales o
          reglamentarias, así como para administrar nuestros derechos (por ejemplo, para hacer valer nuestras
          reclamaciones ante los Tribunales) o con fines estadísticos o históricos.
        </p>
        <p>
          Cuando ya no necesitemos usar sus datos personales, se eliminarán de nuestros sistemas y registros o se
          anonimizarán para que ya no podamos identificarlos.
        </p>
      </div>

      <div className='section-wrapper'>
        <p className='section-title'>7. DERECHOS EN RELACIÓN CON SUS DATOS PERSONALES</p>
        <p>
          Cualquier persona puede retirar su consentimiento en cualquier momento, cuando el mismo se haya otorgado para
          el tratamiento de sus datos. En ningún caso, la retirada de este consentimiento condiciona la ejecución del
          contrato de suscripción o las relaciones generadas con anterioridad.
        </p>
        <p>Igualmente, puede ejercer los siguientes derechos:</p>

        <ul>
          <li>Solicitar el acceso a sus datos personales o su rectificación cuando sean inexactos.</li>
          <li>
            Solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los
            que fueron recogidos.
          </li>
          <li>Solicitar la limitación de su tratamiento en determinadas circunstancias.</li>
          <li>
            Solicitar la oposición al tratamiento de sus datos por motivos relacionados con su situación particular.
          </li>
          <li>Solicitar la portabilidad de los datos en los casos previstos en la normativa.</li>
          <li>Otros derechos reconocidos en las normativas aplicables.</li>
        </ul>
        <p>
          Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido al responsable a su dirección postal o
          electrónica (indicadas en el apartado 1), indicando la referencia “Datos Personales”, especificando el derecho
          que se quiere ejercer y respecto a qué datos personales. En caso de divergencias con la empresa en relación
          con el tratamiento de sus datos, puede presentar una reclamación ante la Agencia de Protección de Datos
          (www.agpd.es).
        </p>
      </div>

      <div className='section-wrapper'>
        <p className='section-title'>8. SEGURIDAD DE SUS DATOS PERSONALES</p>
        <p>
          Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que hemos adoptado todas
          las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales
          suministrados de su alteración, pérdida y tratamientos o accesos no autorizados.
        </p>
      </div>

      <div className='section-wrapper'>
        <p className='section-title'>9. ACTUALIZACIÓN DE SUS DATOS PERSONALES</p>
        <p>
          Es importante que para que podamos mantener sus datos personales actualizados, nos informe siempre que haya
          habido alguna modificación en ellos, en caso contrario, no respondemos de la veracidad de los mismos. No nos
          hacemos responsables de la política de privacidad respecto a los datos personales que pueda facilitar a
          terceros por medio de los enlaces disponibles en nuestra página web.
        </p>
      </div>

      <div className='section-wrapper'>
        <p className='section-title'>10. CONTACTO</p>
        <p>
          Si tiene cualquier duda o preocupación acerca de la forma en que tratamos y usamos sus datos personales o
          desea ejercer cualquiera de los derechos anteriormente descritos, póngase en contacto con manu@bethey.com.
        </p>
        <p>Fecha de publicación: 5 de Agosto de 2020</p>
      </div>
    </div>
  )
}

export default EsContent
