import React from 'react'
import ComponentStyled from './styled'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { Container } from '@material-ui/core'

const TermsConsumers = () => {
  return (
    <ComponentStyled>
      <div className='page-wrapper'>
        <Container maxWidth='lg'>
          <Logo className='logo' />
          <h1 className='page-title'>Términos y condiciones consumidores</h1>
        </Container>
      </div>
    </ComponentStyled>
  )
}

export default TermsConsumers
