import React from 'react'
import ComponentStyled from './styled'
import { AppBar, VideosTable, Footer } from '../../organisms'
import { Container } from '@material-ui/core'

const Dashboard = () => {
  return (
    <ComponentStyled>
      <Container className='page-container'>
        <AppBar />
        <div className='videos-table-wrapper'>
          <VideosTable />
        </div>
        <Footer />
      </Container>
    </ComponentStyled>
  )
}

Dashboard.defaultProps = {}

Dashboard.propTypes = {}

export default Dashboard
