import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import Dialog from '@material-ui/core/Dialog'
import { RegularButton, RegularInput, SingleDatePicker, RegularSelect } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Formik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { ReactComponent as TimerIcon } from '../../../assets/images/timer.svg'
import { VIDEO_STATUS } from '../../../config/constants'

const UpdateVideoModal = ({ onClose, open, onSubmit, isFetching, video, categories }) => {
  const title = _.get(video, 'title', '')
  const status = _.get(video, 'status', '')
  const publicationDate = _.get(video, 'publicationDate', null)
  const category = video.category && { value: video.category.id, label: video.category.nameEs }
  const time = publicationDate && moment(publicationDate).format('HH:mm')
  let publishOptions = []

  if (status === VIDEO_STATUS.public) {
    publishOptions = [
      { value: VIDEO_STATUS.public, label: i18n.updateVideoModal.keepPublic },
      { value: VIDEO_STATUS.private, label: i18n.updateVideoModal.makePrivate },
    ]
  } else if (status === VIDEO_STATUS.programmed) {
    publishOptions = [
      { value: VIDEO_STATUS.programmed, label: i18n.updateVideoModal.programmed },
      { value: VIDEO_STATUS.private, label: i18n.updateVideoModal.makePrivate },
    ]
  } else if (status === VIDEO_STATUS.private) {
    publishOptions = [
      { value: VIDEO_STATUS.public, label: i18n.updateVideoModal.makePublic },
      { value: VIDEO_STATUS.programmed, label: i18n.updateVideoModal.programmed },
      { value: VIDEO_STATUS.private, label: i18n.updateVideoModal.keepPrivate },
    ]
  }

  let defaultStatus = _.find(publishOptions, { value: status })

  return (
    <Dialog classes={{ paper: 'dialog-paper' }} onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <ComponentStyled>
        <Formik
          initialValues={{ title, status: defaultStatus, publicationDate, time, category }}
          validate={values => {
            const errors = {}

            if (!values.title) {
              errors.title = i18n.requiredField
            }

            if (values.status?.value === VIDEO_STATUS.programmed && values.time && values.publicationDate) {
              const today = moment().format('YYYY-MM-DD HH:mm')
              const publicationDate = moment(values.publicationDate).format('YYYY-MM-DD')
              const formatDate = moment(`${publicationDate} ${values.time}`).format('YYYY-MM-DD HH:mm')
              if (moment(formatDate).isSameOrBefore(today)) {
                errors.time = i18n.timeError
              }
            }

            return errors
          }}
          onSubmit={data => {
            const videoId = _.get(video, 'id')
            data.categoryId = _.get(data, 'category.value', '')
            data = _.omit(data, ['category'])
            data.status = _.get(data, 'status.value', '')

            if (data.status === VIDEO_STATUS.public) {
              if (status !== VIDEO_STATUS.public) {
                data.status = VIDEO_STATUS.programmed
                data.publicationDate = moment()
              } else {
                data = _.omit(data, ['status', 'publicationDate', 'time'])
              }
            } else if (data.status === VIDEO_STATUS.programmed) {
              const date = moment(data.publicationDate).format('YYYY-MM-DD')
              data.publicationDate = moment(`${date} ${data.time}`)
              data = _.omit(data, ['time'])
            } else if (data.status === VIDEO_STATUS.private) {
              data = _.omit(data, ['publicationDate', 'time'])
            }

            onSubmit(videoId, data)
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <p className='title'>{i18n.updateVideoModal.modalTitle}</p>

              <RegularInput
                handleOnChange={handleChange}
                value={values.title}
                name='title'
                label={i18n.updateVideoModal.title}
                placeholder={i18n.updateVideoModal.titlePlaceholder}
                error={touched.title && errors.title}
              />

              <RegularSelect
                options={publishOptions}
                handleOnChange={status => {
                  setFieldValue('status', status)
                }}
                label={i18n.updateVideoModal.whenPublish}
                defaultValue={defaultStatus}
              />

              {values.status.value === VIDEO_STATUS.programmed && (
                <Grid container spacing={6} alignItems='flex-end'>
                  <Grid item xs={12} sm={7}>
                    <SingleDatePicker
                      handleOnChange={date => {
                        setFieldValue('publicationDate', date)
                      }}
                      minDate={moment().toDate()}
                      placeholder={i18n.updateVideoModal.publishDatePlaceholder}
                      value={values.publicationDate}
                      label={i18n.updateVideoModal.publishDate}
                      error={errors.publicationDate && touched.publicationDate && errors.publicationDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <RegularInput
                      handleOnChange={handleChange}
                      value={values.time}
                      name='time'
                      type='time'
                      icon={<TimerIcon />}
                      error={errors.time && touched.time && errors.time}
                    />
                  </Grid>
                </Grid>
              )}

              <RegularSelect
                options={categories}
                handleOnChange={category => {
                  setFieldValue('category', category)
                }}
                label={i18n.updateVideoModal.selectCategory}
                defaultValue={category}
                keyValue='id'
                keyLabel='nameEs'
              />

              <div className='actions-wrapper'>
                <p className='cancel'>
                  <span onClick={onClose}>{i18n.updateVideoModal.cancel}</span>
                </p>
                <RegularButton
                  loading={isFetching}
                  type='submit'
                  textUppercase={false}
                  label={i18n.updateVideoModal.confirm}
                />
              </div>
            </form>
          )}
        </Formik>
      </ComponentStyled>
    </Dialog>
  )
}

UpdateVideoModal.defaultProps = {
  isFetching: false,
  categories: [],
}

UpdateVideoModal.propTypes = {
  isFetching: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  video: PropTypes.object,
  categories: PropTypes.array,
}

export default UpdateVideoModal
