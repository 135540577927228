import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import { ReactComponent as PlayIcon } from '../../../assets/images/play.svg'
import i18n from '../../../assets/i18n'

const ProductItem = ({ onClick, amount, formatPrice, isActive, freeVideosAmount }) => {
  return (
    <ComponentStyled isActive={isActive} onClick={onClick}>
      <p className='videos'>
        {amount} {i18n.productItem.videos}
      </p>
      <PlayIcon className='play-icon' />
      <p className='price'>{formatPrice}</p>
      {freeVideosAmount > 0 && (
        <p className='free-videos'>
          {i18n.productItem.giveYou} {freeVideosAmount}
        </p>
      )}
    </ComponentStyled>
  )
}

ProductItem.defaultProps = {
  onClick: () => {},
  freeVideosAmount: 0,
}

ProductItem.propTypes = {
  amount: PropTypes.number,
  onClick: PropTypes.func,
  formatPrice: PropTypes.string,
  isActive: PropTypes.bool,
  freeVideosAmount: PropTypes.number,
}

export default ProductItem
