import React from 'react'
import ComponentStyled from './styled'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { Container } from '@material-ui/core'
import EsContent from './esContent'

const CookiesTerms = () => {
  return (
    <ComponentStyled>
      <div className='page-wrapper'>
        <Container maxWidth='lg'>
          <Logo className='logo' />
          <EsContent />
        </Container>
      </div>
    </ComponentStyled>
  )
}

export default CookiesTerms
