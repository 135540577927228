export const APP_URL = process.env.REACT_APP_APP_URL
export const APP_SHARE_URL = process.env.REACT_APP_SHARE_URL
export const AWS_DOMAIN = process.env.REACT_APP_AWS_DOMAIN
export const AWS_REDIRECT_SIGNIN = process.env.REACT_APP_AWS_REDIRECT_SIGNIN
export const AWS_REDIRECT_SIGNOUT = process.env.REACT_APP_AWS_REDIRECT_SIGNOUT
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET
export const AWS_REGION = process.env.REACT_APP_AWS_REGION
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID
export const AWS_STORAGE_REGION = process.env.REACT_APP_AWS_STORAGE_REGION
export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID
export const AWS_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID

export const VIDEO_STATUS = { public: 'public', programmed: 'programmed', private: 'private' }
export const MAX_VIDEO_SECONDS = 60
