import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

const EsContent = () => {
  return (
    <div>
      <h1 className='page-title'>Política de cookies</h1>
      <div className='section-wrapper'>
        <p className='section-subtitle'>¿Qué son las cookies?</p>
        <p>
          Una cookie es cualquier tipo de dispositivo de almacenamiento y recuperación de datos que se utilice en el
          equipo terminal de un usuario con la finalidad de almacenar información y recuperar la información ya
          almacenada, según establece el artículo 22.2 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de
          la información y de comercio electrónico (LSSI).
        </p>
        <p>
          Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navega.
          En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerle como
          usuario, obtener información sobre sus hábitos de navegación, o personalizar la forma en que se muestra el
          contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.
        </p>
        <p className='section-subtitle'>Clases de cookies</p>
        <p>
          A continuación, se realiza una clasificación de las cookies en función de una serie de categorías. No
          obstante, es necesario tener en cuenta que una misma cookie puede estar incluida en más de una categoría.
        </p>
        <p>Tipos de cookies según la entidad que las gestione :</p>
        <ul>
          <li>
            Cookies propias: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio
            gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
          </li>
          <li>
            Cookies de tercero: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que
            no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.
          </li>
        </ul>
        <p className='section-subtitle'>Tipos de cookies según su finalidad :</p>
        <ul>
          <li>
            Cookies técnicas: son aquellas que permiten al usuario la navegación a través de una página web, plataforma
            o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo
            aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus
            funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la
            sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el
            proceso de compra de un pedido, gestionar el pago, controlar el fraude vinculado a la seguridad del
            servicio, realizar la solicitud de inscripción o participación en un evento, contar visitas a efectos de la
            facturación de licencias del software con el que funciona el servicio (sitio web, plataforma o aplicación),
            utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o
            sonido, habilitar contenidos dinámicos (por ejemplo, animación de carga de un texto o imagen) o compartir
            contenidos a través de redes sociales.
          </li>

          <li>
            Cookies de preferencias o personalización: son aquellas que permiten recordar información para que el
            usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de
            otros usuarios, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el usuario realiza
            una búsqueda, el aspecto o contenido del servicio en función del tipo de navegador a través del cual el
            usuario accede al servicio o de la región desde la que accede al servicio, etc.
          </li>

          <li>
            Cookies de análisis o medición: son aquellas que permiten al responsable de las mismas el seguimiento y
            análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas, incluida la
            cuantificación de los impactos de los anuncios. La información recogida mediante este tipo de cookies se
            utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, con el fin de introducir
            mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
          </li>

          <li>
            Cookies de publicidad comportamental: son aquellas que almacenan información del comportamiento de los
            usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite
            desarrollar un perfil específico para mostrar publicidad en función del mismo.
          </li>
        </ul>
        <p>Tipos de cookies según el plazo de tiempo que permanecen activadas :</p>
        <ul>
          <li>
            Cookies de sesión: son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede a una
            página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del
            servicio solicitado por el usuario en una sola ocasión y desaparecen al terminar la sesión.
          </li>

          <li>
            Cookies persistentes: son aquellas en las que los datos siguen almacenados en el terminal y pueden ser
            accedidos y tratados durante un periodo definido por el responsable de la cookie.
          </li>
        </ul>

        <p className='section-subtitle'>Cookies utilizadas por este sitio web</p>
        <p>
          Este sitio web utiliza cookies de sesión y permanentes, técnicas y de análisis. Las cookies usadas tienen, en
          todo caso, la finalidad de hacer más eficaz la navegación. En ningún caso estas cookies proporcionan por sí
          mismas datos de carácter personal y no se usan para la recogida de los mismos.
        </p>
        <p>
          A continuación, se indica el tipo de cookies utilizadas por nuestro sitio web, junto con la información acerca
          de su gestión (propia o por terceros), finalidad, función y período de conservación:
        </p>

        <p>Cookies propias:</p>
        <table>
          <thead>
            <tr>
              <th>Nombre de la cookie</th>
              <th>Titular</th>
              <th>Finalidad</th>
              <th>Duración</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_gcl_au</td>
              <td>bethey.com</td>
              <td>
                Utilizada por Google AdSense para experimentar con la eficiencia publicitaria a través de las webs
                usando sus servicios.
              </td>
              <td>3 meses</td>
            </tr>
            <tr>
              <td>_hjid</td>
              <td>bethey.com</td>
              <td>
                Conservar la identificación del usuario de forma aleatoria y exclusiva de ese sitio en el navegador
              </td>
              <td>1 año</td>
            </tr>
          </tbody>
        </table>

        <p>Cookies de terceros:</p>
        <table>
          <thead>
            <tr>
              <th>Nombre de la cookie</th>
              <th>Titular</th>
              <th>Finalidad</th>
              <th>Duración</th>
              <th>Más información</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_stripe_mid</td>
              <td>Stripe</td>
              <td>Prevención y detección de fraude</td>
              <td>1 año</td>
              <td>https://stripe.com/gb/privacy</td>
            </tr>

            <tr>
              <td>_stripe_sid</td>
              <td>Stripe</td>
              <td>Prevención y detección de fraude</td>
              <td>30 min</td>
              <td>https://stripe.com/gb/privacy</td>
            </tr>

            <tr>
              <td>private_machine_indentifier</td>
              <td>Stripe</td>
              <td></td>
              <td>1 año</td>
              <td></td>
            </tr>

            <tr>
              <td>m</td>
              <td>Stripe</td>
              <td>Prevención y detección de fraude</td>
              <td>2 años</td>
              <td>https://stripe.com/gb/privacy</td>
            </tr>
          </tbody>
        </table>

        <p className='section-subtitle'>Consentimiento</p>
        <p>
          Le informamos que podemos utilizar cookies en su equipo siempre que haya dado previamente su consentimiento,
          salvo en los supuestos en los que las cookies sean necesarias para la navegación por esta página web y la
          prestación de los servicios.
        </p>
        <p>
          Asimismo, le informamos que puede bloquear o deshabilitar las cookies a través de la configuración de su
          navegador. Si las bloquea o deshabilita, podrá seguir usando nuestro sitio web, aunque el uso de algunos de
          los servicios podrá ser limitado y por tanto su experiencia en nuestro sitio web menos satisfactoria.
        </p>
        <p>
          Si desea revocar su consentimiento respecto a nuestra política de cookies, deberá eliminarlas a través de la
          configuración de sus navegadores de Internet. Estos son los enlaces de los distintos navegadores que informan
          sobre las cookies:
        </p>
        <ul>
          <li>
            Para más información sobre el navegador Firefox desde aquí:
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we'
            >
              https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we
            </a>
          </li>

          <li>
            Para más información sobre el navegador Chrome desde aquí:{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647'
            >
              http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
            </a>
          </li>

          <li>
            Para más información sobre el navegador Internet Explorer desde aquí:{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies'
            >
              https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>

          <li>
            Para más información sobre el navegador Safari desde aquí:{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES'
            >
              https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES
            </a>
          </li>
        </ul>
        <p className='section-subtitle'>Más información</p>
        <p>
          Para más información respecto al tratamiento de sus datos personales, puede acceder a nuestra{' '}
          <Link to={routes.termsConsumers} target='_blank'>
            Política de Privacidad
          </Link>
          , así como el{' '}
          <Link to={routes.legalWarning} target='_blank'>
            Aviso legal
          </Link>
          .
        </p>
        <p>Fecha de publicación: 5 de Agosto de 2020</p>
      </div>
    </div>
  )
}

export default EsContent
