import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  padding: 30px 0;

  .logo {
    max-width: 130px;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }

  .page-title {
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
  }

  .section-wrapper {
    margin-bottom: 30px;

    table {
      margin-bottom: 30px;
      text-align: left;
      width: 100%;

      th {
        border-bottom: 1px solid ${colors.black2};
      }

      th,
      td {
        padding: 10px;
      }
    }

    .section-subtitle {
      font-weight: bold;
    }

    .section-title,
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`

export default ComponentStyled
