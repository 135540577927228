import React, { useEffect } from 'react'
import ComponentStyled from './styled'
import { getProducts, startPayment, getBeneficiaries } from '../../../api'
import _ from 'lodash'
import { formatter } from '../../../utils'
import { CARD_ELEMENT_OPTIONS } from '../../../config/stripe'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { RegularButton, RegularSelect } from '../../atoms'
import { ProductItem } from '../../molecules'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import { toast } from 'react-toastify'
import i18n from '../../../assets/i18n'
import { Container, Grid } from '@material-ui/core'

const Products = () => {
  const history = useHistory()
  const params = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = React.useState(false)
  const [loadingBeneficiaries, setLoadingBeneficiaries] = React.useState(false)
  const [product, setProduct] = React.useState(null)
  const [beneficiaryId, setBeneficiaryId] = React.useState(null)
  const [list, setList] = React.useState([])
  const [beneficiariesList, setBeneficiariesList] = React.useState([])

  useEffect(() => {
    fetchProducts()
    fetchBeneficiaries()
  }, [])

  const fetchProducts = async () => {
    try {
      setLoading(true)
      const productsRes = await getProducts()
      const productsList = _.orderBy(_.get(productsRes, 'data', []), ['videosAmount', 'asc'])
      setList(productsList)
    } catch (e) {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const fetchBeneficiaries = async () => {
    try {
      setLoadingBeneficiaries(true)
      const beneficiariesRes = await getBeneficiaries()
      const beneficiariesList = _.get(beneficiariesRes, 'data', [])
      setBeneficiariesList(beneficiariesList)
    } catch (e) {
      toast(i18n.beneficiaryError, { type: 'error' })
    } finally {
      setLoadingBeneficiaries(false)
    }
  }

  const handleChangeBeneficiary = e => {
    setBeneficiaryId(e.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const userCode = _.get(params, 'userCode')
    if (!stripe || !elements || !product || !userCode) {
      return
    }

    try {
      setLoading(true)
      const data = {
        token: userCode,
        productId: product.id,
      }

      if (beneficiaryId) data.beneficiaryId = beneficiaryId

      const { clientSecret } = await startPayment(data)
      const confirmCardPaymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      const status = _.get(confirmCardPaymentResult, 'paymentIntent.status')
      if (status === 'succeeded') {
        const productVideos = _.get(product, 'videosAmount', 0)
        history.push({
          pathname: routes.productResult,
          search: `?videos=${productVideos}`,
        })
      }
    } catch (e) {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ComponentStyled bgImage={require('../../../assets/images/payment-bg.png')}>
      <div className='page-wrapper'>
        <div className='init-content'>
          <Container maxWidth='lg'>
            <Grid container justify='center'>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Logo className='logo' />

                <p className='title'>{i18n.products.title}</p>
                <p className='desc'>{i18n.products.desc}</p>
                <div className='products-wrapper'>
                  {_.map(list, (v, i) => {
                    const amount = _.get(v, 'videosAmount', '')
                    const freeVideosAmount = _.get(v, 'freeVideosAmount', 0)
                    const price = _.get(v, 'price', 0) / 100
                    const format = formatter.format(price)
                    return (
                      <div className='product' key={`product-${i}`}>
                        <ProductItem
                          amount={amount}
                          isActive={product === v}
                          formatPrice={format}
                          freeVideosAmount={freeVideosAmount}
                          onClick={() => {
                            setProduct(v)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>

                {beneficiariesList.length > 0 && (
                  <RegularSelect
                    label={i18n.products.beneficiaryTitle}
                    defaultValue={{ value: '', label: i18n.products.selectBeneficiary }}
                    handleOnChange={handleChangeBeneficiary}
                    keyValue='id'
                    keyLabel='nameEs'
                    options={beneficiariesList}
                  />
                )}

                <p className='terms'>
                  {i18n.products.apply}{' '}
                  <Link to={routes.termsConsumers} target='_blank'>
                    {i18n.products.terms}
                  </Link>
                  ,{' '}
                  <Link to={routes.legalWarning} target='_blank'>
                    {i18n.products.legalWarning}
                  </Link>
                  ,{' '}
                  <Link to={routes.privacyConsumers} target='_blank'>
                    {i18n.products.privacy}
                  </Link>
                </p>

                <p className='note'>{i18n.products.note}</p>
                <p className='note'>{i18n.products.note2}</p>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container maxWidth='xs'>
          {product && (
            <form onSubmit={handleSubmit} className='payment-form' autoComplete='off'>
              <CardElement className='card-element' options={CARD_ELEMENT_OPTIONS} />
              <div className='btn-wrapper'>
                <RegularButton
                  disabled={!stripe}
                  type='submit'
                  label={i18n.products.buyVideos}
                  loading={loading || loadingBeneficiaries}
                />
              </div>
              <p className='note'>{i18n.products.note3}</p>
            </form>
          )}
        </Container>
      </div>
    </ComponentStyled>
  )
}

export default Products
