import React, { Component } from 'react'
import Routes from '../routes'
import 'react-toastify/dist/ReactToastify.min.css'
import { store, persistor } from '../../../config/redux'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import { PUBLISHABLE_KEY } from '../../../config/stripe'
import { loadStripe } from '@stripe/stripe-js'
import { theme as styledTheme } from '../../../config/styled'
import { GlobalStyle } from '../../../config/styled'
import { ToastContainer, toast } from 'react-toastify'
import { AWS_CONFIG, AUTH_CONFIG } from '../../../config/aws'
import { Auth } from 'aws-amplify'
import Amplify from 'aws-amplify'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import theme from '../../../config/theme'

Amplify.configure(AWS_CONFIG)
Auth.configure({ oauth: AUTH_CONFIG })

const stripePromise = loadStripe(PUBLISHABLE_KEY)

class App extends Component {
  render() {
    const materialTheme = createMuiTheme(theme)

    return (
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={materialTheme}>
              <ThemeProvider theme={styledTheme}>
                <GlobalStyle />
                <Routes />
                <ToastContainer
                  autoClose={3000}
                  hideProgressBar={false}
                  bodyClassName='grow-font-size'
                  draggable={false}
                  position={toast.POSITION.BOTTOM_CENTER}
                />
              </ThemeProvider>
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </Elements>
    )
  }
}

export default App
