import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg'

const FileInput = ({ hasMarginBottom, placeholder, name, handleOnChange, handleOnBlur, error, label }) => {
  const [file, setFile] = useState(null)
  const inputRef = useRef()

  const handleClickClose = () => {
    inputRef.current.value = null
    setFile(null)
    handleOnChange(null)
  }

  const handleChangeFile = e => {
    setFile(e.target.files[0])
    handleOnChange(e.target.files[0])
  }

  return (
    <ContainerStyled error={error ? true : false} hasMarginBottom={hasMarginBottom}>
      <div className='file-placeholder-wrapper'>
        <div className='file-input-wrapper'>
          <input
            ref={inputRef}
            placeholder={placeholder}
            type='file'
            name={name}
            onBlur={handleOnBlur ? handleOnBlur : null}
            onChange={handleChangeFile}
          />
          {label && !file && <p className='label'>{label}</p>}
        </div>

        {file && (
          <div className='file-placeholder'>
            <div className='remove-file-wrapper'>
              <p className='file-name'>{file.name}</p>
              <CloseIcon className='remove-file' onClick={handleClickClose} />
            </div>
          </div>
        )}
      </div>

      {error && <p className='input-error'>{error}</p>}
    </ContainerStyled>
  )
}

FileInput.defaultProps = {
  error: '',
  hasMarginBottom: true,
  capture: false,
}

FileInput.propTypes = {
  hasMarginBottom: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default FileInput
