import styled from 'styled-components'

const ComponentStyled = styled.div`
  padding: 30px 0;

  .logo {
    max-width: 130px;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }

  .page-title {
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    font-weight: bold;
  }

  .section-wrapper {
    margin-bottom: 30px;
    .section-title,
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`

export default ComponentStyled
