import styled from 'styled-components'

const ComponentStyled = styled.div`
  .page-container {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    .videos-table-wrapper {
      flex: 1;
    }
  }
`

export default ComponentStyled
