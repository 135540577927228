import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  min-height: 100vh;

  .logo {
    display: block;
    margin: 0 auto 30px auto;
    height: auto;
    max-width: 95px;
  }

  .init-content {
    background-image: url(${props => props.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .page-wrapper {
    padding: 35px 0;
  }

  .payment-form {
    margin: 50px 0 0 0;

    .btn-wrapper {
      text-align: center;
      margin-bottom: 15px;
    }

    .card-element {
      margin-bottom: 30px;
    }
  }

  .products-wrapper {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    flex-wrap: wrap;

    .product {
      min-width: 105px;
      max-width: 100%;
      margin: 10px 5px;
      display: flex;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: ${colors.darkPurple2};
    text-align: center;
    margin-bottom: 15px;
  }

  .terms {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #23002b;

    a {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #23002b;
      text-decoration: underline;
    }
  }

  .desc {
    text-align: center;
    line-height: 20px;
  }

  .note {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #23002b;
  }
`

export default ComponentStyled
