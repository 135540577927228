export default {
  home: '/',
  dashboard: '/dashboard',
  products: '/products/:userCode',
  productResult: '/products-result',
  shareApp: '/share/:username/:activityId',
  cookies: '/cookies',
  privacyCreators: '/privacy-creators',
  termsCreators: '/terms-and-conditions-creators',
  privacyConsumers: '/privacy-consumers',
  termsConsumers: '/terms-and-conditions-consumers',
  legalWarning: '/legal',
}
