import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  text-align: center;
  background-image: url(${props => props.bgImage});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .logo {
    max-width: 95px;
    height: auto;
    margin-bottom: 40px;
  }

  .content-wrapper {
    padding: 35px 0;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: ${colors.darkPurple2};
    margin-bottom: 25px;
  }

  .desc {
    margin-bottom: 20px;
    line-height: 20px;
  }
`

export default ComponentStyled
