import colors from '../../../assets/colors'

export default {
  cookieConsent: {
    background: 'linear-gradient(-349.38deg, #b31100 6.93%, #f8466e 93.37%)',
    display: 'flex',
    alignItems: 'center',
  },
  cookiesBtn: {
    border: `1px solid ${colors.white}`,
    background: 'transparent',
    color: colors.white,
    margin: '10px 15px',
  },
  cookiesDeclineBtn: { background: 'transparent', color: colors.white, margin: '10px 15px', padding: 0 },
  cookiesCopy: { color: colors.white, margin: 0, display: 'flex', alignItems: 'center' },
  cookiesIcon: { minWidth: 30, maxWidth: '100%', marginRight: 15 },
  cookiesLink: { color: colors.white, textDecoration: 'underline' },
}
