import ComponentStyled from './styled'
import React, { createRef } from 'react'
import { RegularButton } from '../../atoms'
import { DeleteVideoModal, UpdateVideoModal, UploadVideoModal } from '../../modals'
import MaterialTable from 'material-table'
//import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg'
import i18n from '../../../assets/i18n'
import moment from 'moment'
import _ from 'lodash'
import * as API from '../../../api'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Component } from 'react'
import { Storage } from 'aws-amplify'

const tableSettings = {
  options: {
    toolbar: false,
    paginationType: 'stepped',
    showTitle: false,
    draggable: false,
  },
  localization: {
    body: {
      emptyDataSourceMessage: i18n.videosTable.noResults,
    },
    pagination: {
      labelRowsSelect: i18n.videosTable.rows,
      nextAriaLabel: i18n.videosTable.nextPage,
      nextTooltip: i18n.videosTable.nextPage,
      lastAriaLabel: i18n.videosTable.lastPage,
      lastTooltip: i18n.videosTable.lastPage,
      previousAriaLabel: i18n.videosTable.prevPage,
      previousTooltip: i18n.videosTable.prevPage,
      firstAriaLabel: i18n.videosTable.firstPage,
      firstTooltip: i18n.videosTable.firstPage,
    },
  },
}

const MTCreatedAt = rowData => <p className='date'>{moment(rowData.createdAt).format('DD-MM-YYYY HH:mm')}</p>
const MTPublicationDate = rowData => (
  <p className='date'>{moment(rowData.publicationDate).format('DD-MM-YYYY HH:mm')}</p>
)

const MTStatus = rowData => <p className='status'>{_.get(i18n, `videosTable.${rowData.status}`, '')}</p>
const MTActions = onClickEdit => (
  <>
    <EditIcon className='edit-icon' onClick={onClickEdit} />
    {/*<DeleteIcon className='delete-icon' onClick={onClickDelete} />*/}
  </>
)

class VideosTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingDeleteVideo: false,
      isFetchingUpdateVideo: false,
      isFetchingUploadVideo: false,
      openDeleteVideoModal: false,
      openUpdateVideoModal: false,
      openUploadVideoModal: false,
      activeVideo: {},
      categories: [],
    }
    this.tableRef = createRef()
  }

  async componentDidMount() {
    const response = await API.fetchVideoCategories()
    const categories = _.get(response, 'data', [])
    this.setState({ categories })
  }

  updateTableData = () => this.tableRef.current && this.tableRef.current.onQueryChange()

  handleOpenUpdateVideoModal = video => {
    this.setState({ activeVideo: video }, () => {
      this.setState({ openUpdateVideoModal: true })
    })
  }
  handleCloseUpdateVideoModal = () => this.setState({ openUpdateVideoModal: false })

  handleOpenDeleteVideoModal = async video => {
    this.setState({ activeVideo: video }, () => {
      this.setState({ openDeleteVideoModal: true })
    })
  }
  handleCloseDeleteVideoModal = () => this.setState({ openDeleteVideoModal: false })

  handleOpenUploadVideoModal = () => this.setState({ openUploadVideoModal: true })
  handleCloseUploadVideoModal = () => this.setState({ openUploadVideoModal: false })

  handleDeleteVideo = async () => {
    const { activeVideo } = this.state
    const videoId = _.get(activeVideo, 'id')
    try {
      this.setState({ isFetchingDeleteVideo: true })
      await API.deleteVideo(videoId)
      this.updateTableData()
    } catch (e) {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      this.setState({ isFetchingDeleteVideo: false })
      this.handleCloseDeleteVideoModal()
    }
  }

  handleUpdateVideo = async (videoId, data) => {
    try {
      this.setState({ isFetchingUpdateVideo: true })
      await API.updateVideo(videoId, data)
      this.updateTableData()
    } catch (e) {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      this.setState({ isFetchingUpdateVideo: false })
      this.handleCloseUpdateVideoModal()
    }
  }

  handleUploadVideo = async (videoData, video) => {
    try {
      this.setState({ isFetchingUploadVideo: true })
      const postVideoRes = await API.postVideo(videoData)
      const originalName = _.get(postVideoRes, 'originalName')
      const videoName = _.get(video, 'name', '')
      const extension = _.last(videoName.split('.'))
      const type = _.get(video, 'type')
      const name = `${originalName}.${extension}`
      await Storage.put(name, video, { level: 'private', contentType: type })
      this.updateTableData()
    } catch (e) {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      this.setState({ isFetchingUploadVideo: false })
      this.handleCloseUploadVideoModal()
    }
  }

  getTableData = async query => {
    const { logout } = this.props
    const orderBy = _.get(query, 'orderBy.field', '')
    const orderDirection = _.get(query, 'orderDirection', '')
    const page = _.get(query, 'page') + 1
    const pageSize = _.get(query, 'pageSize')
    const offset = _.get(query, 'page', '') * pageSize
    let sort = []

    if (orderBy && orderDirection) sort = [`${orderBy},${_.toUpper(orderDirection)}`]

    try {
      const result = await API.fetchVideos({ page, offset, limit: pageSize, sort, join: 'category' })
      return { data: result.data, page: result.page - 1, totalCount: result.total }
    } catch (e) {
      const statusCode = _.get(e, 'response.status', 1)
      if (statusCode === 403) logout()
      return { data: [], page: 0, totalCount: 0 }
    }
  }

  render() {
    const tableColumns = [
      { title: i18n.videosTable.name, field: 'title' },
      { title: i18n.videosTable.uploadDate, field: 'createdAt', render: rowData => MTCreatedAt(rowData) },
      {
        title: i18n.videosTable.publishDate,
        field: 'publicationDate',
        render: rowData => MTPublicationDate(rowData),
      },
      { title: i18n.videosTable.status, field: 'status', render: rowData => MTStatus(rowData) },
      {
        title: i18n.videosTable.actions,
        sorting: false,
        render: rowData =>
          MTActions(
            () => this.handleOpenUpdateVideoModal(rowData),
            () => this.handleOpenDeleteVideoModal(rowData)
          ),
      },
    ]

    const {
      isFetchingDeleteVideo,
      isFetchingUpdateVideo,
      isFetchingUploadVideo,
      openUploadVideoModal,
      openUpdateVideoModal,
      openDeleteVideoModal,
      activeVideo,
      categories,
    } = this.state

    return (
      <ComponentStyled>
        <div className='upload-btn-wrapper'>
          <RegularButton onClick={this.handleOpenUploadVideoModal} label={i18n.videosTable.uploadVideo} />
        </div>
        <MaterialTable
          tableRef={this.tableRef}
          options={tableSettings.options}
          localization={tableSettings.localization}
          columns={tableColumns}
          data={this.getTableData}
        />
        <DeleteVideoModal
          onDeleteVideo={this.handleDeleteVideo}
          open={openDeleteVideoModal}
          onClose={this.handleCloseDeleteVideoModal}
          isFetching={isFetchingDeleteVideo}
        />
        <UpdateVideoModal
          onSubmit={this.handleUpdateVideo}
          open={openUpdateVideoModal}
          onClose={this.handleCloseUpdateVideoModal}
          video={activeVideo}
          isFetching={isFetchingUpdateVideo}
          categories={categories}
        />
        <UploadVideoModal
          onSubmit={this.handleUploadVideo}
          open={openUploadVideoModal}
          onClose={this.handleCloseUploadVideoModal}
          isFetching={isFetchingUploadVideo}
          categories={categories}
        />
      </ComponentStyled>
    )
  }
}

VideosTable.propTypes = {
  logout: PropTypes.func,
}

export default VideosTable
