export default {
  next: 'Siguiente',
  close: 'Cerrar',
  accept: 'Aceptar',
  yes: 'Sí',
  no: 'No',
  warning: 'Atención',
  continue: 'Continuar',
  generalError: 'Ha ocurrido un error',

  //Molecules
  productItem: {
    videos: 'Vídeos',
    giveYou: 'Te regalamos',
  },

  //Pages
  products: {
    title: 'Disfruta de los vídeos exclusivos de las celebrities',
    desc: 'Todos tus ídolos están en Bethey Vídeos totalmente exclusivos No te pierdas nada',
    apply: 'Aplican',
    terms: 'Términos y condiciones',
    note: 'Los créditos de vídeos estarán disponibles en la aplicación de Bethey cuando se efectúe el pago',
    buyVideos: 'Comprar vídeos',
  },
  productsResult: {
    ready: '¡Listo!',
    desc: 'Hemos añadido {videos} vídeos de crédito a tu cuenta para que los disfrutes ahora mismo.',
    openApp: 'Abrir la aplicación de Bethey',
  },
}
