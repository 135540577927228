import ComponentStyled from './styled'
import React from 'react'
import { Grid } from '@material-ui/core'
import i18n from '../../../assets/i18n'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

const Footer = () => {
  return (
    <ComponentStyled>
      <Grid container spacing={3} direction='row' justify='space-between' alignItems='center'>
        <Grid item xs={12}>
          <p className='content'>
            <Link to={routes.termsCreators} target='_blank' className='link'>
              {i18n.footer.terms}
            </Link>
            ,{' '}
            <Link to={routes.legalWarning} target='_blank' className='link'>
              {i18n.footer.legalWarning}
            </Link>
            ,{' '}
            <Link to={routes.privacyCreators} target='_blank' className='link'>
              {i18n.footer.privacy}
            </Link>
            ,{' '}
            <Link to={routes.cookies} target='_blank' className='link'>
              {i18n.footer.cookies}
            </Link>
          </p>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

export default Footer
