import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  background: ${colors.white};
  padding: 15px 5px;
  cursor: pointer;
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  transition: 0.5s ease all;
  width: 100%;
  text-align: center;

  .videos {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.3px;
    color: ${colors.darkPurple2};
  }

  .free-videos {
    font-weight: normal;
    font-size: 11px;
    color: ${colors.darkPurple2};
    line-height: 11px;
  }

  .price {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
    color: ${colors.darkPurple2};
    line-height: 20px;
  }

  .play-icon {
    margin: 10px 0;

    path {
      fill: ${colors.darkPurple2};
    }
  }

  ${props =>
    props.isActive === true &&
    css`
      background: linear-gradient(349.38deg, #b31100 6.93%, #f8466e 93.37%);
      .play-icon path {
        fill: ${colors.white};
      }

      .videos {
        font-weight: 500;
      }

      .videos,
      .free-videos,
      .price {
        color: ${colors.white};
      }
    `}
`

export default ComponentStyled
