import ComponentStyled from './styled'
import React, { useState } from 'react'
import { RegularButton, RegularInput, FacebookButton } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

const LoginForm = () => {
  const [isFetching, setIsFetching] = useState(false)

  const handleSubmitLogin = async ({ username, password }) => {
    try {
      setIsFetching(true)
      await Auth.signIn({ username, password })
    } catch {
      toast(i18n.generalError, { type: 'error' })
    } finally {
      setIsFetching(false)
    }
  }

  const handleClickFacebook = () => {
    Auth.federatedSignIn({ provider: 'Facebook' })
  }

  return (
    <ComponentStyled>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validate={values => {
              let errors = {}
              if (!values.username) {
                errors.username = i18n.requiredField
              }
              if (!values.password) {
                errors.password = i18n.requiredField
              }
              return errors
            }}
            onSubmit={data => {
              handleSubmitLogin(data)
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <img className='login-logo' src={require('../../../assets/images/logo.gif')} alt='Bethey Logo' />

                <RegularInput
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  value={values.username}
                  name='username'
                  label={i18n.loginForm.email}
                  error={errors.username && touched.username && errors.username}
                />

                <RegularInput
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  value={values.password}
                  name='password'
                  type='password'
                  label={i18n.loginForm.password}
                  error={errors.password && touched.password && errors.password}
                />
                <Grid container direction='row' justify='center' alignItems='center'>
                  <Grid item xs={12} className='btn-wrapper'>
                    <RegularButton loading={isFetching} type='submit' label={i18n.loginForm.signIn} variant='big' />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12} className='fb-wrapper'>
              <p className='fb-title'>{i18n.loginForm.youCanToo}</p>
              <FacebookButton onClick={handleClickFacebook} loading={isFetching} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

LoginForm.propTypes = {
  login: PropTypes.func,
  history: PropTypes.object,
}

export default LoginForm
