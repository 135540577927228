import React from 'react'
import {
  Splash,
  Products,
  ProductsResult,
  TermsConsumers,
  PrivacyConsumers,
  PrivacyCreators,
  TermsCreators,
  Dashboard,
  ShareApp,
  CookiesTerms,
  LegalWarning,
  NotFound,
} from '../../pages'
import routes from '../../../config/routes'
import PropTypes from 'prop-types'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { PrivateRoute } from '../../templates'
import CookieConsent from 'react-cookie-consent'
import i18n from '../../../assets/i18n'
import { ReactComponent as CookieIcon } from '../../../assets/images/cookie.svg'
import { Link } from 'react-router-dom'
import styles from './styles'

class Routes extends React.PureComponent {
  static propTypes = {
    auth: PropTypes.object,
  }

  render() {
    const { auth } = this.props

    if (!auth.init) return null

    return (
      <BrowserRouter>
        <Switch>
          <Route component={Products} path={routes.products} />
          <Route component={ProductsResult} path={routes.productResult} />
          <Route component={ShareApp} path={routes.shareApp} />

          <Route component={TermsConsumers} path={routes.termsConsumers} />
          <Route component={TermsCreators} path={routes.termsCreators} />
          <Route component={PrivacyConsumers} path={routes.privacyConsumers} />
          <Route component={PrivacyCreators} path={routes.privacyCreators} />
          <Route component={CookiesTerms} path={routes.cookies} />
          <Route component={LegalWarning} path={routes.legalWarning} />

          <PrivateRoute component={Dashboard} path={routes.dashboard} />

          <Route component={Splash} exact path={routes.home} />

          <Route component={NotFound} path={'*'} />
        </Switch>
        <CookieConsent
          enableDeclineButton
          location='bottom'
          buttonText={i18n.cookieConsent.acept}
          declineButtonText={i18n.cookieConsent.decline}
          cookieName='betheycookie'
          style={styles.cookieConsent}
          buttonStyle={styles.cookiesBtn}
          declineButtonStyle={styles.cookiesDeclineBtn}
          expires={150}
        >
          <p style={styles.cookiesCopy}>
            <CookieIcon style={styles.cookiesIcon} />
            <span>
              {i18n.cookieConsent.cookiesPolicyCopy}{' '}
              <Link to={routes.cookies} target='_blank' style={styles.cookiesLink}>
                {i18n.cookieConsent.cookiesPolicy}
              </Link>
            </span>
          </p>
        </CookieConsent>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({ auth: state.auth })

const enhanced = compose(connect(mapStateToProps))

export default enhanced(Routes)
