import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  position: relative;

  .label,
  .input-error {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }

  .label {
    color: ${colors.black2};
  }

  .input-error {
    color: ${colors.red};
  }

  .file-placeholder-wrapper {
    position: relative;
  }

  .file-input-wrapper {
    position: relative;
    min-height: 60px;
    max-height: 100%;
    border: 1px dashed #f01d22;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .file-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;

    .remove-file-wrapper {
      border: 1px solid rgba(35, 0, 43, 0.5);
      box-sizing: border-box;
      border-radius: 6px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .file-name {
        margin-bottom: 0;
      }

      .remove-file {
        cursor: pointer;
      }
    }
  }

  ${props =>
    props.hasMarginBottom === true &&
    css`
      margin-bottom: 25px;
    `}
`

export default ComponentStyled
