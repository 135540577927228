import React from 'react'
import { APP_URL } from '../../../config/constants'
import { RegularButton } from '../../atoms'
import ComponentStyled from './styled'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import i18n from '../../../assets/i18n'
import { Container } from '@material-ui/core'
import _ from 'lodash'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProductsResult = ({ history }) => {
  const queryParams = _.get(history, 'location.search', '')
  const params = new URLSearchParams(queryParams)
  const videos = params.get('videos')

  const handleClickOpenApp = () => {
    window.open(APP_URL, '_blank')
  }

  return (
    <ComponentStyled bgImage={require('../../../assets/images/payment-bg.png')}>
      <Container maxWidth='xs'>
        <div className='content-wrapper'>
          <Logo className='logo' />
          <p className='title'>{i18n.productsResult.ready}</p>
          <p className='desc'>{i18n.formatString(i18n.productsResult.desc, { videos })}</p>
          <RegularButton onClick={handleClickOpenApp} label={i18n.productsResult.openApp} />
        </div>
      </Container>
    </ComponentStyled>
  )
}

ProductsResult.propTypes = {
  history: PropTypes.object,
}

const enhanced = compose(withRouter)

export default enhanced(ProductsResult)
