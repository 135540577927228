export const PUBLISHABLE_KEY =
  'pk_test_51GuLECJdr3JMsHo1iyWybJF9qTwCznpDb6p1GmwFI5nwc3wJWrMRMadaL6Fio13hmVRFLizTLYbDcvNAnmoCuAPr00n3SfYYxV'

export const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#1A1A1A',
      fontFamily: '"Gorly","Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#F01D22',
      iconColor: '#F01D22',
    },
  },
}
