import ComponentStyled from './styled'
import React from 'react'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { UserAvatar } from '../../atoms'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import { toast } from 'react-toastify'

const AppBar = ({ logout, user }) => {
  const avatarUrl = _.get(user, 'attributes.picture', '')

  const handleClickCloseSession = async () => {
    try {
      await logout()
    } catch {
      toast(i18n.generalError, { type: 'error' })
    }
  }

  return (
    <ComponentStyled>
      <Grid container spacing={3} direction='row' justify='space-between' alignItems='center'>
        <Grid item xs={3} sm={3}>
          <Logo className='logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <p className='note'>{i18n.appBar.note}</p>
        </Grid>
        <Grid item xs={3} sm={3} className='user-wrapper'>
          <UserAvatar onClick={handleClickCloseSession} urlImage={avatarUrl} />
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

AppBar.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.object,
}

export default AppBar
