import React from 'react'

const EsContent = () => {
  return (
    <div>
      <h1 className='page-title'>Aviso legal</h1>
      <p>
        En cumplimiento con el deber de información recogido en el del artículo 10 de la Ley 34/2002, de 11 de julio, de
        Servicios de la Sociedad de la Información y Comercio Electrónico, se exponen los siguientes datos
        identificativos del prestador del servicio a través de la plataforma de Bethey:
      </p>
      <p>Nombre: AGVAR BYTHEY, S.L.</p>
      <p>Dirección: C/Major de Sarriá nº 172, 08017 Barcelona</p>
      <p>NIF: B67498394</p>
      <p>Correo electrónico: manu@bethey.com</p>
      <p>Registro Mercantil de Barcelona, Tomo 47061, Folio 111, Sección 8, Hoja 539795</p>
    </div>
  )
}

export default EsContent
