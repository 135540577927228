import { configureAxiosDefaults, instance as axios } from './utils'
import qs from 'qs'

export default axios

export function configureToken(token) {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : null)
}

export function configureLanguage(language) {
  configureAxiosDefaults('Accept-Language', language)
}

export const fetchVideos = params => {
  const url = `/api/videos?${qs.stringify(params, { skipNulls: true })}`
  return axios.get(url)
}

export const postVideo = data => {
  const url = '/api/videos'
  return axios.post(url, data)
}

export const updateVideo = (videoId, data) => {
  const url = `/api/videos/${videoId}`
  return axios.patch(url, data)
}

export const deleteVideo = videoId => {
  const url = `/api/videos/${videoId}`
  return axios.delete(url)
}

export const fetchVideoCategories = () => {
  const url = '/api/categories'
  return axios.get(url)
}

export const getProducts = () => {
  const url = `/api/products`
  return axios.get(url)
}

export const getBeneficiaries = () => {
  const url = `/api/beneficiaries`
  return axios.get(url)
}

export const createPayment = () => {
  const url = `/api/payments/create`
  return axios.post(url)
}

export const startPayment = data => {
  const url = `/api/payments/start`
  return axios.post(url, data)
}
