import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  padding: 15px 0;

  .logo {
    max-width: 100%;
    width: 175px;
    height: auto;
  }

  .note {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${colors.black2};
    margin-bottom: 0;
  }

  .user-wrapper {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      cursor: pointer;
      margin-left: 15px;
    }
  }
`

export default ComponentStyled
