import * as authActions from './actions'
import { Auth } from 'aws-amplify'
import _ from 'lodash'

export const checkSession = () => (dispatch, getState, { api }) => {
  new Promise(resolve => {
    const { token, user } = getState().auth
    dispatch(authActions.setUser(user))
    dispatch(authActions.setToken(token))
    api.configureToken(token)
    dispatch(authActions.setAppInit())
    resolve()
  })
}

export const setUser = user => async (dispatch, getState, { api, setError }) => {
  try {
    user = formatUserData(user)
    dispatch(authActions.setUser(user))
    const signInUserSession = await Auth.currentSession()
    const token = _.get(signInUserSession, 'accessToken.jwtToken', null)
    dispatch(authActions.setToken(token))
    api.configureToken(token)
    return token
  } catch (e) {
    dispatch(setError(e))
    return false
  }
}

export const logout = () => (dispatch, getState, { api }) => {
  dispatch(authActions.logout())
  api.configureToken(null)
  Auth.signOut()
}

const formatUserData = user => {
  const attributes = _.get(user, 'attributes', {})

  const formatUser = {}
  _.assign(formatUser, { attributes })

  return formatUser
}
